<template>
  <div class="defect-overview">
    <div class="title-box">
      <div class="title-box__icon">
        <img src="../../../assets/icons/dashboard/Defect Overview.png" />
        <span>Inspection Defect Overview</span>
      </div>

      <el-button
        type="info"
        @click="$router.push({ path: '/analysis', query: { tabsIndex: 0, time } })"
      >
        View Record
      </el-button>
    </div>

    <div class="defect-overview__content _flex">
      <div class="defect-overview__content__left _font-weight__600" data-label="Total Defects">
        {{ sum }}
      </div>
      <div class="defect-overview__content__right">
        <div
          class="_flex defect-overview__right__item _font-weight__600 _font-size__sub__heading"
          v-for="item in progress"
          :key="item.key"
        >
          <div class="defect-overview__right__label">{{ item.label }}</div>

          <!-- 75px 为 label 宽度 20px 为边距 -->
          <div
            class="defect-overview__right__progress"
            :style="{
              background: item.color,
              minWidth: '28px',
              width: Number(detail[item.key])
                ? 'calc(' + (detail[item.key] / maxNum) * 100 + '%' + ' - 75px - 20px)'
                : '0px'
            }"
          >
            {{ detail[item.key] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { props } from './props'

export default {
  props,
  data() {
    return {
      progress: [
        {
          color: '#1F97F5',
          label: 'Minor',
          key: 'minorCount'
        },
        {
          color: 'var(--color-warning)',
          label: 'Moderate',
          key: 'moderateCount'
        },
        {
          color: 'var(--color-primary)',
          label: 'Major',
          key: 'majorCount'
        }
      ]
    }
  },
  computed: {
    maxNum() {
      return Math.max(...this.progress.map((item) => this.detail[item.key]))
    },
    sum() {
      let num = 0
      this.progress.forEach((item) => {
        num += Number(this.detail[item.key])
      })
      return num
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.defect-overview {
  &__content {
    &__left {
      width: 150px;
      font-size: 40px;
      margin-right: 20px;
      &::after {
        content: attr(data-label);
        display: block;
        font-size: 15px;
        margin-top: 13px;
      }
    }

    &__right {
      width: calc(100% - 150px - 20px);
    }
  }

  &__right {
    &__item {
      height: 30px;
      line-height: 30px;
      margin-bottom: 20px;
    }

    &__label {
      width: 75px;
      height: 100%;
      margin-right: 20px;
    }

    &__progress {
      width: 38px;
      border-radius: 25px;
      padding: 0 10px;
      color: var(--color-white);
      text-align: right;
    }
  }
}
</style>
